import { useState, useEffect, useRef, useCallback, RefCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Returns a value which updates whenever the defined value updates but debounced
 * by delay milliseconds.
 *
 * @see https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 *
 * @param value
 * @param delay
 * @returns {any}
 */
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const staticDelay = useRef(delay);
  if (staticDelay.current !== delay) {
    console.warn(
      'useDebounce does not react to changes of delay, update the hook to support this if needed'
    );
  }

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, staticDelay.current);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  );

  return debouncedValue;
}

/**
 * Returns the previous value of state/prop.
 * @param value
 */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Returns dimensions of element.
 *
 * Use:
 *   const [bind, { height: viewHeight }] = useMeasure();
 *   ...
 *   <div {...bind}> // element to measure
 *     ...
 *   </div>
 *
 * @returns {*[]}
 */
export function useMeasure<T>(): [
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { ref: RefCallback<T> },
  { height: number; width: number; left: number; top: number }
] {
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [element, setRef] = useState<Element | undefined>(undefined);

  const onRef = useCallback(
    (ref?: unknown) => {
      setRef(ref instanceof Element ? ref : undefined);
    },
    [setRef]
  );

  useEffect(() => {
    if (!(element instanceof Element)) return;
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        set(entries[0].contentRect);
      }
    });
    observer.observe(element);
    return () => observer.disconnect();
  }, [element]);

  return [{ ref: onRef }, bounds];
}

/**
 * Returns a value and it's setter.
 * The value will be overridden by specified prop whenever it changes.
 *
 * @param prop
 * @param defaultValue
 * @returns {*[]}
 */
export function useStateFromProp(prop, defaultValue = undefined) {
  const [value, setValue] = useState(
    typeof prop !== 'undefined' ? prop : defaultValue
  );

  useEffect(() => {
    setValue(typeof prop !== 'undefined' ? prop : defaultValue);
  }, [prop, defaultValue]);

  return [value, setValue];
}

/**
 * Returns a stable reference if the comparator function does not find any differences between runs.
 *
 * @param next
 * @param compare
 * @return next
 */
export function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;

  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}
