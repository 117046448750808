import React, { useCallback, useState } from 'react';
import ClassNames from 'classnames';
import { Box } from '@chakra-ui/react';
import { useAlerts } from '@contact/react';
import { Button, HTML } from '../';
import { AlertIcon, isSmallIcon } from './AlertIcon';
import './Alert.scss';

export interface RollOffAlertProps {
  alert: any;
}

export function RollOffAlert({ alert }: RollOffAlertProps) {
  const contentClassName = 'contactUIComponentsAlertMessage_contentInner';
  const [isCommentAccepted, setCommentAccepted] = useState(false);
  const [, , removeAlert] = useAlerts();
  const remove = useCallback(() => {
    removeAlert(alert);
  }, [alert, removeAlert]);

  const onAcceptClick = useCallback(() => {
    setCommentAccepted(true);
  }, [setCommentAccepted]);

  return alert ? (
    <Box
      id={alert.name ? `alert_${alert.name}` : undefined}
      as="div"
      sx={alert.styles}
      ref={alert.ref}
      className={ClassNames(
        'contactUIComponentsAlertMessage',
        `contactUIComponentsAlerts_container--notEmpty contactUIComponentsAlertMessage_container--rollOff`
      )}
    >
      {!isCommentAccepted && (
        <div
          className={
            isSmallIcon(alert.icon)
              ? 'contactUIComponentsAlertMessage_small_icon'
              : 'contactUIComponentsAlertMessage_icon'
          }
        >
          <AlertIcon
            icon={alert.icon}
            fill={alert.styles?.['--alert-icon-color']}
          />
        </div>
      )}
      <div className="contactUIComponentsAlertMessage_contentContainer">
        <div className="contactUIComponentsAlertMessage_content">
          {alert.header ? (
            <div className="contactUIComponentsAlertMessage_header">
              {alert.header}
            </div>
          ) : undefined}
          <div className={contentClassName}>
            <HTML html={isCommentAccepted ? 'Thanks!' : alert?.content} />
          </div>
        </div>
        {!isCommentAccepted && (
          <div className="contactUIComponentsAlertMessage_ghostButton">
            <Button
              variant="ghost"
              type="button"
              className="yes"
              onClick={onAcceptClick}
              title="Yes"
            >
              {alert.buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </Box>
  ) : null;
}
