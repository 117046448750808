import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ArrowUpIcon = (props: IconProps): ReactElement => (
  <Icon width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M8 16.1c-.317 0-.587-.112-.812-.337a1.108 1.108 0 01-.338-.813V4.275L2.325 8.8c-.217.233-.483.35-.8.35-.317 0-.592-.117-.825-.35A1.143 1.143 0 01.375 8c0-.3.108-.567.325-.8L7.2.7a.925.925 0 01.375-.238 1.56 1.56 0 01.863 0A.751.751 0 018.8.7l6.5 6.5c.217.233.325.5.325.8 0 .3-.108.567-.325.8-.233.233-.508.35-.825.35-.317 0-.583-.117-.8-.35L9.15 4.275V14.95c0 .317-.113.588-.338.813A1.107 1.107 0 018 16.1z"
      fill="#303030"
    />
  </Icon>
);
