import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const SelectedTickIcon = ({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      d="M9 0C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9zM6.6 12.9 3.3 9.6c-.1-.1-.2-.4-.2-.6 0-.2.1-.5.3-.6.3-.4.8-.4 1.2 0L7.2 11l6.2-6.2c.4-.4.9-.4 1.3 0s.4.9 0 1.3l-6.8 6.8c-.4.3-1 .3-1.3 0z"
      fill={color as string}
    />
  </Icon>
);
