import { useSiteCoreIdentity } from '@contact/data-access-hooks';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useOptionalUser } from '../User';

export interface SiteCoreContextState {
  cookie: string;
  isCookieLoading: boolean;
}

const siteCoreContextDefaultState: SiteCoreContextState = {
  cookie: '',
  isCookieLoading: false,
};

export const SiteCoreContext = createContext<SiteCoreContextState>(
  siteCoreContextDefaultState
);

export function useSiteCoreContext() {
  return useContext(SiteCoreContext);
}

export function SiteCoreProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  const [cookie, setCookie] = useState(siteCoreContextDefaultState.cookie);

  const { bp = '', ba = '', token = '' } = useOptionalUser() || {};
  const { data: identity, isLoading: isLoadingIdentity } = useSiteCoreIdentity(
    {
      version: 'v2',
      bpId: bp,
      baId: ba,
      token,
    },
    undefined,
    { suspense: false }
  );

  const siteCoreContextState: SiteCoreContextState = {
    cookie: cookie,
    isCookieLoading: isLoadingIdentity,
  };

  useEffect(() => {
    if (identity?.tracking) {
      Cookies.set('PS_Track', identity.tracking, {
        secure: true,
        domain: '.contact.co.nz',
        expires: 3,
        sameSite: 'None',
        path: '/',
      });
    }
  }, [identity?.tracking]);

  useEffect(() => {
    if (identity?.cookieValue) {
      setCookie(identity.cookieValue);
    }
  }, [identity?.cookieValue]);

  return (
    <SiteCoreContext.Provider value={siteCoreContextState}>
      {children}
    </SiteCoreContext.Provider>
  );
}
