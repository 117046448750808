import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ElectricitySolidIcon = (props: IconProps): ReactElement => (
  <Icon width={13} height={22} viewBox="0 0 13 22" fill="none" {...props}>
    <path
      d="M5.452 12.696c-1.517.005-2.983.014-4.45.011-.339 0-.735.11-.94-.3-.196-.39.12-.64.3-.908C2.812 7.842 5.268 4.189 7.727.536c.045-.067.092-.134.136-.203.15-.236.345-.41.639-.298.294.113.246.371.199.623-.462 2.506-.92 5.012-1.376 7.518a1.54 1.54 0 000 .185h.428l4.363-.013c.295 0 .635 0 .722.318.053.253.009.517-.124.74-2.595 4.08-5.2 8.155-7.818 12.224-.05.082-.094.187-.171.223-.156.073-.366.187-.493.133a.535.535 0 01-.23-.443c.067-.633.183-1.261.287-1.89.382-2.299.767-4.599 1.16-6.953"
      fill="#303030"
    />
  </Icon>
);
