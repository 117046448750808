import React from 'react';
import { AspectRatio, Box, Skeleton, Stack } from '@chakra-ui/react';

/** This needs to be an odd number of values */
const barHeights = [30, 50, 40, 65, 80, 55, 60, 45, 38];
const bottomLabelWidth = 32;
const bottomLabelWidthPadding = 32;
const barRectHorizontalMargin = 63;

export interface UsageGraphLoadingProps {
  width: number;
  height: number;
  barWidth?: number;
  barSpacing: number;
}

export function UsageGraphLoading({
  width,
  height,
  barWidth,
  barSpacing = 0,
}: UsageGraphLoadingProps) {
  const isBarWidthFixed =
    typeof barWidth === 'number' && barWidth > 0 && width > 0;

  let maxBarCount = isBarWidthFixed
    ? Math.floor((width - barRectHorizontalMargin * 2) / barWidth)
    : 0;
  if (maxBarCount % 2 === 0) {
    maxBarCount -= 1;
  }
  if (maxBarCount === 0) {
    maxBarCount = 1;
  }

  return (
    <AspectRatio ratio={width / height}>
      <Box position="relative">
        <Stack
          position="absolute"
          top="56px"
          left="24px"
          bottom="21px"
          right="0"
          justifyContent="space-between"
        >
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <Box
                key={i}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Skeleton
                  width="32px"
                  height="12px"
                  marginRight="9px"
                  borderRadius="6px"
                />
                <Box width="100%" height="1px" backgroundColor="#3030301A" />
              </Box>
            ))}
        </Stack>
        <Box
          position="absolute"
          top="0"
          left={`${barRectHorizontalMargin}px`}
          bottom="8px"
          right={`${barRectHorizontalMargin}px`}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="center"
          overflow="hidden"
        >
          {barHeights
            .filter((height, i, bars) => {
              if (!isBarWidthFixed) {
                return true;
              }
              const targetCount = maxBarCount;
              return (
                i >= Math.min((bars.length - targetCount) / 2) &&
                i < Math.max((bars.length + targetCount) / 2)
              );
            })
            .map((height, i, bars) => (
              <Box
                key={i}
                style={{
                  flex: isBarWidthFixed ? undefined : 1,
                  width: isBarWidthFixed ? `${barWidth}px` : undefined,
                }}
                width={isBarWidthFixed ? `${barWidth}px` : undefined}
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
              >
                {i === Math.floor(bars.length / 2) && (
                  <Box
                    width="1px"
                    height="calc(20% - 4px)"
                    backgroundColor="#3030301A"
                  />
                )}
                <Skeleton
                  width={`calc(100% - ${barSpacing}px)`}
                  height={`${height}%`}
                />
                <Skeleton
                  width={bottomLabelWidth + 'px'}
                  height="12px"
                  marginTop="7px"
                  borderRadius="6px"
                  isLoaded={
                    isBarWidthFixed
                      ? i %
                          Math.ceil(
                            (bottomLabelWidth + bottomLabelWidthPadding) /
                              barWidth
                          ) !==
                        0
                      : false
                  }
                />
              </Box>
            ))}
        </Box>
      </Box>
    </AspectRatio>
  );
}
