import React from 'react';

export const WhiteCheck = (props) => {
  return (
    <svg viewBox="0 0 31 24" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2 12.589L11.5 22 29 2"
      />
    </svg>
  );
};
