import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ThumbsUpIconNewSelected = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g clip-path="url(#clip0_154_993)">
      <path
        d="M20.0183 21L22.7638 15.0564L22.7638 11.1328C22.7638 10.9568 22.8445 10.7881 22.9838 10.6781C23.0205 10.6488 23.8785 9.97409 25.0813 9.97409C26.4234 9.97409 28.5502 12.0936 28.5502 14.6017C28.5502 16.1859 27.9855 18.1587 27.6261 19.2294H34.7766C36.3314 19.2294 37.6588 20.3808 37.7982 21.8549C37.8715 22.6763 37.6001 23.4757 37.0574 24.0624C37.5341 24.5391 37.8128 25.1918 37.8128 25.8812C37.8128 26.8859 37.2408 27.766 36.3827 28.1987C36.5587 28.5507 36.6541 28.9467 36.6541 29.3574C36.6541 30.3622 36.0747 31.2422 35.224 31.6749C35.488 32.203 35.5687 32.8117 35.4293 33.4131C35.1726 34.5865 34.0652 35.4372 32.8038 35.4372H22.1771C21.3703 35.4372 20.0796 35.1805 19.1115 34.6818C18.7082 35.7965 17.6374 36.5959 16.3907 36.5959L12.9218 36.5959C11.323 36.5959 10.0322 35.2978 10.0322 33.7064L10.0322 24.4511C10.0322 22.8523 11.3303 21.5542 12.9218 21.5542L18.7082 21.5542C19.0309 21.5542 19.2875 21.8109 19.2875 22.1336V22.3536L19.4269 22.2803L20 21.0396V21H20.0183Z"
        fill="#E52A32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9963 48C10.7441 48 -1.15855e-06 37.2559 0 24.0037C1.15855e-06 10.7514 10.7441 0.00732306 23.9963 0.00732422C37.2486 0.00732538 47.9927 10.7514 47.9927 24.0037C47.9927 37.2559 37.2559 48 23.9963 48ZM23.9963 1.04874C17.6526 1.04874 11.9248 3.61558 7.76654 7.77387C3.61559 11.9248 1.04141 17.6599 1.04141 24.0037C1.0414 30.3474 3.60825 36.0752 7.76654 40.2335C11.9248 44.3917 17.6599 46.9586 23.9963 46.9586C30.3401 46.9586 36.0678 44.3917 40.2261 40.2335C44.3844 36.0752 46.9513 30.3401 46.9513 24.0037C46.9513 17.6599 44.3844 11.9322 40.2261 7.77387C36.0678 3.61558 30.3328 1.04874 23.9963 1.04874Z"
        fill="#E52A32"
      />
    </g>
    <defs>
      <clipPath id="clip0_154_993">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 48)"
        />
      </clipPath>
    </defs>
  </Icon>
);
