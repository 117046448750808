import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const InfoSolidSharpLargeIcon = (props: IconProps): ReactElement => (
  <Icon width={22} height={23} viewBox="0 0 22 23" fill="none" {...props}>
    <path
      d="M11 .5a11 11 0 100 22 11 11 0 000-22zm1.397 17.55H9.594V7.784h2.803V18.05zm-.253-11.916A1.6 1.6 0 0111 6.5c-1.017 0-1.525-.46-1.525-1.378 0-.92.508-1.376 1.525-1.371 1.018 0 1.527.456 1.525 1.369a1.334 1.334 0 01-.381 1.016v-.002z"
      fill="#303030"
    />
  </Icon>
);
