import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const LightBulbSolidIcon = (props: IconProps): ReactElement => (
  <Icon width={23} height={23} viewBox="0 0 23 23" fill="none" {...props}>
    <path
      d="M21.09 10.763l-.68-1.489-1.494-.684 1.495-.68.679-1.494.684 1.495 1.49.679-1.49.684-.684 1.49zm-3-4.18l-1.028-2.235-2.235-1.033 2.235-1.028L18.09.052l1.033 2.235 2.236 1.028-2.236 1.033-1.033 2.236zM7.815 22.866c-.57 0-1.058-.21-1.464-.627a2.097 2.097 0 01-.608-1.517h4.15c0 .594-.204 1.1-.612 1.517a1.976 1.976 0 01-1.466.627zM3.76 19.673v-2.09h8.113v2.09H3.761zm.25-3.137a8.048 8.048 0 01-2.836-2.837A7.422 7.422 0 01.117 9.833c0-2.14.749-3.957 2.245-5.453 1.496-1.497 3.314-2.245 5.453-2.245 2.139 0 3.958.748 5.456 2.245 1.498 1.496 2.247 3.313 2.247 5.453a7.418 7.418 0 01-1.057 3.87 8.056 8.056 0 01-2.837 2.833H4.011z"
      fill="#303030"
    />
  </Icon>
);
