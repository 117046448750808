import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const ThumbsDownIconNewSelected = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 48 48" {...props}>
    <path
      d="M20.0183 27L22.7638 32.9436L22.7638 36.8672C22.7638 37.0432 22.8445 37.2119 22.9838 37.3219C23.0205 37.3512 23.8785 38.0259 25.0813 38.0259C26.4234 38.0259 28.5502 35.9064 28.5502 33.3983C28.5502 31.8142 27.9855 29.8413 27.6261 28.7706L34.7766 28.7706C36.3314 28.7706 37.6588 27.6192 37.7982 26.1451C37.8715 25.3237 37.6001 24.5243 37.0574 23.9376C37.5341 23.4609 37.8128 22.8082 37.8128 22.1188C37.8128 21.1141 37.2408 20.234 36.3827 19.8013C36.5587 19.4493 36.6541 19.0533 36.6541 18.6426C36.6541 17.6378 36.0747 16.7578 35.224 16.3251C35.488 15.797 35.5687 15.1883 35.4293 14.587C35.1726 13.4135 34.0652 12.5628 32.8038 12.5628L22.1771 12.5628C21.3704 12.5628 20.0796 12.8195 19.1115 13.3182C18.7082 12.2035 17.6374 11.4041 16.3907 11.4041L12.9218 11.4041C11.323 11.4041 10.0322 12.7022 10.0322 14.2936L10.0322 23.5489C10.0322 25.1477 11.3303 26.4458 12.9218 26.4458L18.7082 26.4458C19.0309 26.4458 19.2875 26.1891 19.2875 25.8664L19.2875 25.6464L19.4269 25.7197L20 26.9604L20 27L20.0183 27Z"
      fill="#E52A32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9963 1.1825e-05C10.7441 9.50795e-06 5.3542e-06 10.7441 4.19565e-06 23.9963C3.0371e-06 37.2486 10.7441 47.9927 23.9963 47.9927C37.2486 47.9927 47.9927 37.2486 47.9927 23.9963C47.9927 10.7441 37.2559 1.41434e-05 23.9963 1.1825e-05ZM23.9963 46.9513C17.6526 46.9513 11.9248 44.3844 7.76654 40.2261C3.61559 36.0752 1.04141 30.3401 1.04141 23.9963C1.04141 17.6526 3.60826 11.9248 7.76655 7.76655C11.9248 3.60826 17.6599 1.04142 23.9963 1.04142C30.3401 1.04142 36.0678 3.60826 40.2261 7.76655C44.3844 11.9248 46.9513 17.6599 46.9513 23.9963C46.9513 30.3401 44.3844 36.0679 40.2261 40.2261C36.0678 44.3844 30.3328 46.9513 23.9963 46.9513Z"
      fill="#E52A32"
    />
  </Icon>
);
