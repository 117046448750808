import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const AlertSolidIcon = (props: IconProps): ReactElement => (
  <Icon width={22} height={19} viewBox="0 0 22 19" fill="none" {...props}>
    <path
      d="M1.8 18.6c-.45 0-.78-.187-.988-.562-.208-.375-.204-.755.013-1.138L10 1c.217-.383.55-.575 1-.575.45 0 .783.192 1 .575l9.175 15.9c.217.383.221.763.013 1.138-.209.375-.538.562-.988.562H1.8zM11 7.575a.967.967 0 00-.712.287.968.968 0 00-.288.713V11.5c0 .283.096.52.288.712A.965.965 0 0011 12.5a.968.968 0 00.713-.288A.967.967 0 0012 11.5V8.575a.97.97 0 00-.287-.713.97.97 0 00-.713-.287zm0 7.9c.283 0 .525-.1.725-.3.2-.2.3-.442.3-.725s-.1-.525-.3-.725c-.2-.2-.442-.3-.725-.3s-.525.1-.725.3c-.2.2-.3.442-.3.725s.1.525.3.725c.2.2.442.3.725.3z"
      fill="#303030"
    />
  </Icon>
);
