import moment from 'moment-timezone';

// TODO: Use a proper url
export const termsAndConditionsUrl =
  process.env.NX_PDF_BASE_URL +
  '/-/media/contact/pdfs/terms-and-conditions/specific-campaigns/fourth-trimester-terms-and-conditions?t=' +
  new Date().getTime();

export const userAuthKey = 'userAuth';
export const requestIdKey = 'requestId';
export const passedSubmissionKey = 'passedSubmission';

export const nzTimeZone = 'Pacific/Auckland';
export const trimesterStartDate = moment.tz('2023-11-01', nzTimeZone);
