import React from 'react';
import { animated, useSpring } from 'react-spring';
import './PropertySwitcher.scss';
import {
  PropertySwitcherList,
  PropertySwitcherListProps,
} from './PropertySwitcherList';
import { useCustomer } from '@contact/data-access-hooks';
import { useOptionalUser } from '@contact/react';
import { CloseIcon } from '../Icons';

export interface PropertySwitcherProps {
  isOpen: boolean;
  onClose: () => void;
  onPropertySelect: (ba: string, premise: string) => void;
  target?: React.ReactNode;
  customItemContent?: PropertySwitcherListProps['customItemContent'];
}

export const PropertySwitcher = ({
  isOpen,
  onClose,
  onPropertySelect,
  target,
  customItemContent,
}: PropertySwitcherProps) => {
  const { token = '' } = useOptionalUser() || {};
  const { data: customer } = useCustomer('v2', token, true);

  const [slideAnimation, setSlideAnimation] = useSpring(() => ({
    transform: 'translateX(100%)',
  }));
  const [opacityAnimation, setOpacityAnimation] = useSpring(() => ({
    opacity: 0,
  }));

  setSlideAnimation({
    transform: isOpen ? 'translateX(0%)' : 'translateX(100%)',
    opacity: isOpen ? 1 : 0,
  } as any);

  setOpacityAnimation({
    opacity: isOpen ? 1 : 0,
  });

  return (
    <>
      {target ?? null}
      <div className="propertySwitcher">
        <animated.div
          style={slideAnimation}
          className="propertySwitcher_container"
        >
          <div
            className="propertySwitcher_closeButton"
            role="menuitem"
            onClick={() => onClose()}
            onKeyDown={(e) => {
              e.preventDefault();
              if (e.key === 'Enter') {
                onClose();
              }
            }}
            tabIndex={0}
          >
            <CloseIcon color="#FFF" />
          </div>

          <div className="propertySwitcher_title">
            {customer?.fullName || ''}
          </div>
          {customer && (
            <PropertySwitcherList
              onPropertySelect={onPropertySelect}
              onClose={onClose}
              customItemContent={customItemContent}
            />
          )}
        </animated.div>
        <animated.div
          style={{
            ...opacityAnimation,
            visibility: opacityAnimation.opacity.interpolate((o) =>
              o === 0 ? 'hidden' : 'visible'
            ),
          }}
          className="propertySwitcher_overlay"
          onClick={() => onClose()}
        />
      </div>
    </>
  );
};
