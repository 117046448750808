import {
  BroadbandUsageApiResponse,
  BroadbandUsageParams,
  BroadbandUsagePath,
  Consumption,
  GetBroadbandUsageWeeklyDifferenceParams,
  GetUsageParams,
  GetWeeklyUsageDifferenceParams,
  WeeklyUsageDifference,
} from '@contact/data-access';
import {
  BroadbandRange,
  BroadbandService,
  UsageQueryBaseKey,
  useBroadbandUsage,
  useBroadbandUsageWeeklyDifference,
  useUsage,
  useWeeklyUsageDifference,
} from '@contact/data-access-hooks';
import {
  QueryObserverResult,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import * as Usage from './UsageConstants';
import DateUtil from '../Utilities/DateUtil';
import { useEffect, useMemo } from 'react';

const usageIntervalMapping = {
  [Usage.MONTHLY]: 'monthly',
  [Usage.DAILY]: 'daily',
  [Usage.HOURLY]: 'hourly',
};

const broadbandRangeMapping = {
  [Usage.MONTHLY]: 'monthly' as BroadbandRange,
  [Usage.DAILY]: 'daily' as BroadbandRange,
  [Usage.HOURLY]: 'hourly' as BroadbandRange,
};

export interface UnifiedUsageHookProps
  extends Omit<GetUsageParams, 'interval'>,
    Omit<BroadbandUsageParams, 'start' | 'end' | 'range'> {
  selectedUtility: Usage.Utility;
  period: Usage.Range;
}

export type UnifiedUsageQueryOptions = UseQueryOptions<
  any,
  any,
  Usage.DataItem[],
  any
>;

export type UnifiedUsageQueryResult = UseQueryResult<Usage.DataItem[], any> & {
  rawBroadbandData?: BroadbandUsageApiResponse;
};

export function useUnifiedUsage(
  { selectedUtility, ...props }: UnifiedUsageHookProps,
  options?: UnifiedUsageQueryOptions
): UnifiedUsageQueryResult {
  const mergedOptions = {
    suspense: false,
    staleTime: Infinity, // Reset on page exit
    ...options,
  };

  const usageResult = useUsage(
    { ...props, interval: usageIntervalMapping[props.period] },
    mergeEnabled(
      mergedOptions as UseQueryOptions<any, any, Consumption[], any>,
      selectedUtility === Usage.ELECTRICITY || selectedUtility === Usage.GAS
    )
  );

  const broadbandResult = useBroadbandUsage(
    'v2',
    {
      ...props,
      range: broadbandRangeMapping[props.period],
      start: props.from,
      end: props.to,
    },
    mergeEnabled(mergedOptions as any, selectedUtility === Usage.BROADBAND)
  );

  const broadbandData = useMemo(
    () => mapBroadbandUsage(broadbandResult.data?.services[0]),
    [broadbandResult.data?.services]
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.resetQueries([BroadbandUsagePath]);
      queryClient.resetQueries([UsageQueryBaseKey]);
    };
  }, [queryClient]);

  switch (selectedUtility) {
    case 'electricity':
    case 'gas':
      return usageResult as UseQueryResult<Usage.DataItem[], any>;
    case 'broadband':
      return {
        ...broadbandResult,
        data: broadbandData,
        rawBroadbandData: broadbandResult.data,
      } as UnifiedUsageQueryResult;
  }
}

export interface UnifiedUsageWeeklyDifferenceHookProps
  extends GetWeeklyUsageDifferenceParams,
    GetBroadbandUsageWeeklyDifferenceParams {
  selectedUtility: Usage.Utility;
}

export type UnifiedUsageWeeklyDifferenceData = {
  [K in Usage.UnitMode]: WeeklyUsageDifference;
};

export type UnifiedUsageWeeklyDifferenceQueryOptions = UseQueryOptions<
  any,
  any,
  UnifiedUsageWeeklyDifferenceData,
  any
>;

export function useUnifiedUsageWeeklyDifference(
  { selectedUtility, ...props }: UnifiedUsageWeeklyDifferenceHookProps,
  options?: UnifiedUsageQueryOptions
): QueryObserverResult<UnifiedUsageWeeklyDifferenceData> {
  const mergedOptions = {
    suspense: false,
    staleTime: Infinity, // Reset on page exit
    ...options,
  };

  const usageResult = useWeeklyUsageDifference(
    props,
    mergeEnabled(
      mergedOptions as any,
      selectedUtility === Usage.ELECTRICITY || selectedUtility === Usage.GAS
    )
  );

  const broadbandResult = useBroadbandUsageWeeklyDifference(
    props,
    mergeEnabled(mergedOptions as any, selectedUtility === Usage.BROADBAND)
  );

  return useMemo(() => {
    switch (selectedUtility) {
      case 'electricity':
        return {
          ...(usageResult as any),
          data: usageResult.data
            ? {
                units: usageResult.data.kwh,
                cost: usageResult.data.dollar,
              }
            : undefined,
        };
      case 'gas':
        return {
          data: undefined,
          error: null,
          isError: false,
          isIdle: false,
          isLoading: false,
          isLoadingError: false,
          isRefetchError: false,
          isSuccess: true,
          status: 'success',
        };
      case 'broadband':
        return {
          ...(broadbandResult as any),
          data: broadbandResult.data
            ? {
                units: broadbandResult.data.gb,
              }
            : undefined,
        };
    }
  }, [broadbandResult, selectedUtility, usageResult]);
}

export function mapBroadbandUsage(bbService: BroadbandService | undefined) {
  return bbService?.usage.map(
    (item): Usage.BroadbandDataItem => {
      const date = DateUtil.nzDate(item.rangeStart);
      return {
        date: item.rangeStart,
        endDate: item.rangeEnd,
        value: item.totalGigabytes,
        offpeakValue: '0',
        month: date.month() + 1,
        day: date.date(),
        year: date.year(),
        hour: date.hours(),
        unit: 'Gb',
      };
    }
  );
}

function mergeEnabled<T extends { enabled?: boolean }>(
  source: T,
  enabled: boolean
): T {
  return {
    ...source,
    enabled: enabled && (source?.enabled ?? true),
  };
}
