import React, { FC, useCallback, useMemo, useState } from 'react';
import { IconProps, Stack, Text } from '@contact/ui-volta';
import { Button, Loading } from '../';
import {
  ThumbsDownIcon,
  ThumbsUpIconNew,
  ThumbsDownIconNew,
  ThumbsUpIcon,
  ThumbsUpIconNewSelected,
  ThumbsDownIconNewSelected,
} from '../Icons';
import { Textarea } from '@chakra-ui/react';
import { FormErrorMessage } from '@contact/ui-volta';
import Error from '../Rates/Error';

export type FeedbackValue = 'yes' | 'no';
export type FeedbackComment = '' | null | undefined;

const Options: FeedbackOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    icon: ThumbsUpIcon,
  },
  {
    label: 'No',
    value: 'no',
    icon: ThumbsDownIcon,
  },
];

const OptionsNew: FeedbackOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    icon: ThumbsUpIconNew,
  },
  {
    label: 'No',
    value: 'no',
    icon: ThumbsDownIconNew,
  },
];

const OptionsNewSelected: FeedbackOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    icon: ThumbsUpIconNewSelected,
  },
  {
    label: 'No',
    value: 'no',
    icon: ThumbsDownIconNewSelected,
  },
];

interface FeedbackOption {
  label: string;
  value: FeedbackValue;
  icon: FC<IconProps>;
  height?: string;
  width?: string;
}

interface FeedbackOptionProps extends FeedbackOption {
  visible: boolean;
  selected: boolean;
  onSelect(): void;
  isCommentBox?: boolean;
}

const FeedbackOption = ({
  label,
  icon: Icon,
  visible,
  selected,
  onSelect,
  isCommentBox,
  width,
  height,
}: FeedbackOptionProps) => {
  const [hovered, setHovered] = useState(false);

  const iconColor = useMemo(
    () => (selected ? 'red.600' : hovered ? 'red.650' : 'red.700'),
    [hovered, selected]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="16px"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onClick={visible ? onSelect : undefined}
      opacity={visible ? 1 : 0}
      cursor="pointer"
    >
      {!isCommentBox && (
        <Text fontSize="16px" lineHeight="19px" fontWeight={500}>
          {label}
        </Text>
      )}
      <Icon width={width} height={height} color={iconColor} />
    </Stack>
  );
};

export interface FeedbackProps {
  label: string;
  subLabel?: string;
  value?: FeedbackValue;
  onChange(value: FeedbackValue): void;
  handleTextAreaChange?: (value: string) => void;
  isCommentBox?: boolean;
  commentValue?: string;
  buttonLabel?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isSucess?: boolean;
  isSubmit?: boolean;
  thanksLabel?: string;
  isLoading?: boolean;
  errorMessage?: string;
  surveySubLabel?: string;
}

export const Feedback = ({
  label,
  subLabel,
  value,
  buttonLabel,
  onChange,
  onClick,
  handleTextAreaChange,
  isCommentBox,
  thanksLabel,
  isSucess,
  isSubmit,
  commentValue,
  isLoading,
  errorMessage,
  surveySubLabel,
}: FeedbackProps) => {
  const [isCommentBoxEnabled, setIsCommentBoxEnabled] = useState(false);
  const handleSelect = useCallback(
    (option: FeedbackOption) => () => {
      setIsCommentBoxEnabled(true);
      onChange(option.value);
    },
    [onChange]
  );

  const isVisible = useCallback(
    (option: FeedbackOption) => !value || option.value === value,
    [value]
  );

  const feedBackOptions = isCommentBoxEnabled ? OptionsNewSelected : OptionsNew;
  const error = !isSucess && !isSubmit && !isLoading;

  return isCommentBox ? (
    <Stack
      spacing={{
        base: '24px',
        md: '32px',
      }}
    >
      {!isSubmit ? (
        <>
          <Text fontSize="18px" lineHeight="22px" fontWeight={900}>
            {label}
          </Text>
          {isCommentBox && (
            <Text fontSize="16px" lineHeight="22px" fontWeight={500}>
              {subLabel}
            </Text>
          )}
          <Stack direction="row" paddingLeft="8px" spacing="40px">
            {feedBackOptions.map((option) => (
              <FeedbackOption
                {...option}
                isCommentBox={isCommentBox}
                visible={isVisible(option)}
                selected={option.value === value}
                onSelect={handleSelect(option)}
                height="48px"
                width="48px"
              />
            ))}
          </Stack>
          {isCommentBoxEnabled && isCommentBox && (
            <Stack
              direction="column"
              display="Flex"
              paddingLeft="8px"
              spacing="40px"
            >
              <div>{surveySubLabel}</div>
              <Textarea
                className="success_content"
                value={commentValue}
                maxLength={500}
                onChange={(event) => {
                  handleTextAreaChange?.(event.target.value);
                }}
              ></Textarea>
            </Stack>
          )}
          {isCommentBoxEnabled && (
            <Stack
              direction="column"
              display="Flex"
              paddingLeft="8px"
              spacing="40px"
            >
              <Button
                name="submitButton"
                type="submit"
                className="submit-button"
                onClick={onClick}
                style={{ width: '150px' }}
                variant="secondary"
                id="submit-button"
              >
                {buttonLabel}
              </Button>
              {error && (
                <Error
                  errorClassName="contactUIComponentsRates_survey-error"
                  errorMessage={errorMessage}
                ></Error>
              )}
            </Stack>
          )}
        </>
      ) : isLoading ? (
        <Loading
          customstyle={{
            minWidth: '100%',
            minHeight: '150px',
            marginBottom: '-15px',
          }}
        />
      ) : (
        <Stack
          direction="column"
          display="Flex"
          spacing="20px"
          padding="20px"
          border="1px solid #fad4d6"
          borderRadius="4px"
          max-width="400px"
          width="100%"
          height="100px"
          justifyContent="center"
          alignItems="center"
        >
          <label>{thanksLabel}</label>
        </Stack>
      )}
    </Stack>
  ) : (
    <Stack
      spacing={{
        base: '24px',
        md: '32px',
      }}
    >
      <Text fontSize="18px" lineHeight="22px" fontWeight={900}>
        {label}
      </Text>
      <Stack direction="row" paddingLeft="8px" spacing="40px">
        {Options.map((option) => (
          <FeedbackOption
            {...option}
            visible={isVisible(option)}
            isCommentBox={false}
            selected={option.value === value}
            onSelect={handleSelect(option)}
            height="24px"
            width="32px"
          />
        ))}
      </Stack>
    </Stack>
  );
};
