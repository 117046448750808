import React from 'react';
import { Skeleton } from '@contact/ui-volta';
import classNames from 'classnames';

import './UsageCard.scss';

export interface UsageCardProps {
  title: string;
  value?: string;
  text?: string;
  icon?: string | JSX.Element;
  iconClassName?: string;
  className?: string;
  isAlert?: boolean;
  isLoading?: boolean;
}

export function UsageCard({
  title = '',
  value = '',
  text = '',
  icon,
  iconClassName,
  className,
  isAlert = false,
  isLoading = false,
}: UsageCardProps) {
  return (
    <Skeleton
      isLoaded={!isLoading}
      className={classNames(
        {
          olsUsageCard: true,
          olsUsageCard_isAlert: isAlert,
          olsUsageCard_limitWidth: !!text,
          olsUsageCard_placeholder: isLoading,
        },
        className
      )}
    >
      <div
        className={classNames({
          olsUsageCard_innerContainer: true,
          olsUsageCard_innerContainer_limitWidth: !text,
        })}
      >
        <div
          className={classNames({
            olsUsageCard_iconContainer: !!icon,
            olsUsageCard_iconContainer_empty: !icon,
          })}
        >
          {icon &&
            (typeof icon === 'string' ? (
              <svg className={iconClassName}>
                <use xlinkHref={`#${icon}`} />
              </svg>
            ) : (
              icon
            ))}
        </div>
        <div className="olsUsageCard_contentContainer">
          <div className="olsUsageCard_title">{title}</div>
          <div className="olsUsageCard_value">{value}</div>
          <div className="olsUsageCard_text">{text}</div>
        </div>
      </div>
    </Skeleton>
  );
}
