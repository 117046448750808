import { termsAndConditionsUrl } from '../Config';

export const Namespace = 'TrimesterComponentsTranslations';

export default {
  [Namespace]: {
    translations: {
      title: 'Fourth Trimester sign up',
      header: 'See if you qualify',
      notAvailableOfferRedirectUrl:
        'https://contact.co.nz/support/trimester/end',
      babyBirthDataFieldLabel: 'Baby date of birth or expected due date',
      babyDueDateQuestion: {
        title: 'What date was your baby born?',
        description:
          'You are welcome to apply for Fourth Trimester if your baby has a birth date from 1 to 30 November 2023. ',
        link: 'Read more about the birth date eligibility criteria',
        label: 'Baby’s Birth Date',
      },
      phone: '0800 245 245',
      currentBillingPaymentMethod: {
        title: 'Select your current billing plan',
        descriptionComponent: {
          paragraph1: `To be eligible for this offer, you need to be on a monthly billing cycle (if you receive a weekly or fortnightly bill, you’ll need to change to monthly billing before applying).`,
          paragraph2: `PrePay, SmoothPay, ControlPay, collective and multisite billing customers are not eligible for the Fourth Trimester. If you’ve opted for one of these payment options, you may be able to come off and then apply (subject to any credit check requirements). If you’re one of these customers, please call us `,
        },
        label: '',
        options: [
          { value: 'Monthly', label: 'Monthly' },
          { value: 'PrePay', label: 'PrePay' },
          { value: 'Weekly', label: 'Weekly' },
          { value: 'SmoothPay', label: 'SmoothPay' },
          { value: 'Fortnightly', label: 'Fortnightly' },
          { value: 'Other', label: 'Other' },
        ],
      },
      primaryResidenceQuestion: {
        title: `Is the address we’ll apply Fourth Trimester to, the account holder’s primary residence and where the baby will mostly live?`,
        description:
          'The offer can be applied to one residence only, where the baby primarily lives.',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      authoritySignOnBehalfCheckbox:
        'I have authority to sign up to 4th Trimester on behalf of the primary caregiver, who will be living with the baby at my address.',
      accountDetailsTitle: 'Your account details',
      accountDetailsDescription:
        'To take up this offer, you must be the Account Holder.',
      accountDetailsDescription2:
        'You must enter the Account number and email address exactly as they appear on your Contact invoice, so we know it’s you and we can communicate with you about this offer.  If you do not have an email address with Contact, please call us on ',
      accountDetailsDescription2Link: {
        label: '0800 245 245',
        target: 'tel:0800 245 245',
      },
      accountDetailsDescription3: ' to add this to your account. ',
      accountNumber: 'Account number',
      firstName: 'First name',
      lastName: 'Last name',
      emailAddress: 'Email address',
      phoneNumber: 'Phone number',
      uploadDocumentsQuestion: {
        title: `Upload your baby's documents`,
        descriptionWithTips: {
          apply: `To apply for this offer, we require  `,
          link: 'proof of your baby’s arrival',
          upload: ` to be uploaded here. Acceptable file formats include PDF, JPEG, PNG and TIFF with a total file limit of 6MB. Please note that a filename cannot contain any of the following characters: \\ / : * ? " < > | . `,
          help: {
            text: `Can't upload your documents? Call us on `,
            text2: ' for help',
          },
        },
        descriptionNoTips: {
          apply: `To apply for this offer, we require proof of your baby’s arrival to be uploaded here. Acceptable file formats include PDF, JPEG, PNG and TIFF with a total file limit of 6MB. Please note that a filename cannot contain any of the following characters: \\ / : * ? " < > | . `,
        },
      },
      sendEmailCsrButton: 'Email upload link',
      continueButtonText: 'Submit',
      apiErrorHeader: 'Sorry, something went wrong',
      apiErrorText: 'Please try again later',
      privacyStatement: {
        title: 'Privacy',
        description: `We collect this information from you in order to administer and manage the Fourth Trimester offer. It will be used and disclosed only in accordance with the Privacy Act and our general terms and conditions. Providing this information is optional though if you don't, you won't be able to apply for the offer. You have the right to ask for a copy of any personal information we hold about you and ask for it to be corrected if you think it's wrong.`,
      },
      termsAndConditions: {
        label: '',
        title: 'Terms and Conditions',
        textComponent: {
          text: 'I agree to the offer ',
          text2: ` and acknowledge that all other terms and conditions I'm subject to remain in full effect. I also acknowledge that the information I have provided is true and accurate in every respect and I have authority to apply for the offer. If I am successful in my application, I acknowledge that I will forfeit any portion of the offer if I leave Contact or move house during the offer period. I also acknowledge that the free energy will apply to both estimated and actual bills and no credit will be given for any estimated read which is subsequently adjusted.`,
          link: 'terms and conditions',
          url: termsAndConditionsUrl,
        },
        helperText: 'The T&Cs are also available on our website.',
        error: 'Please accept terms and conditions',
      },
      helpTooltips: {
        documentsUpload: {
          title: `Your baby's documents`,
          content: {
            description:
              "Here are the documents you can use to confirm your baby's birth date:",
            list: [
              'Hospital discharge notice',
              'Plunket Well Child Health Book (first page)',
              "Confirmation of baby's IRD number letter",
              'Adoption order letter',
              'Oranga Tamariki custody letter/guardianship letter',
            ],
          },
        },
        accountNumber: {
          title: '',
          text: 'Head to your ',
          textLink: {
            label: 'online account',
            target: 'https://myaccount.contact.co.nz/',
          },
          text2:
            " to see your account number, or check your confirmation email if you have joined us recently and don't have an online account yet.",
        },
        babyDueDate: {
          title: 'What’s your baby’s birth date?',
          text:
            'At the time of application, the baby must have been born and have a birth date from 1 to 30 November 2023.',
        },
      },
      customErrors: {
        dob: {
          required: 'Date of birth is required',
          notEligibleDate: `Sorry, your baby's birth date doesn't meet our criteria for Fourth Trimester`,
        },
        paymentMethod: {
          required: 'Please select payment method',
          criteriaNotMet: `Sorry, your selection doesn't meet our criteria for Fourth Trimester`,
        },
        careArrangement: {
          required: 'Please select care arrangement',
          criteriaNotMet: `Sorry, your selection doesn't meet our criteria for Fourth Trimester`,
        },
        account: {
          required: 'Account number required',
          notANumber: 'Account should have only numbers in input',
          tooShort: 'Account must have nine numbers in input',
        },
        firstName: 'First name required',
        lastName: 'Last name required',
        email: {
          required: 'Email required',
          notValid: 'Email is not valid',
        },
        phone: {
          required: 'Phone number required',
          notANumber: 'Phone number should have only numbers in input',
          notValid: 'Not a valid phone number',
        },
        uploadDoc: 'Please select at least one file to be uploaded',
        termsAndConditions: 'Please accept terms and conditions',
        emailLinkNotSent: 'Please click Email upload link button',
      },
      sidebar: {
        title: 'What you might need to complete',
        subtitle: 'Average time to complete: 3-5 mins',
        intro: `There are a few eligibility checks we’ll need to complete to add the Fourth Trimester benefit to your Contact Energy plan. All information provided will be treated confidentially and will not be used in conjunction with future market initiatives.`,
        listIntro: `You'll need:`,
        list: [
          {
            key: 'birth-date',
            header: `Your baby’s birth date`,
            content: `To qualify you for this offer, we need to know that your baby has a birth date from 1 to 30 November 2023. `,
            link: 'Find out more',
            icon: '',
          },
          {
            key: 'digital-documentation',
            header: 'Digital documentation',
            link: 'Documents confirming the birth of your baby.',
            content: ` We’ll ask you to upload these documents on the form.`,
            icon: '',
          },
          {
            key: 'account-details',
            header: 'Your account details',
            content:
              ' on your invoice halfway down the front page on the left.',
            content2:
              'You will also need to enter the exact email address that we send your Contact Energy bill to. If you don’t have an email on your account, you will need to add this to your account by calling us on the phone number below. ​',
            link: 'You can find your account number',
            icon: '',
          },
          {
            header: 'Need some help?',
            phoneLink: {
              giveCall: 'Give us a call on ',
              free: ' (toll free)',
              additionalText: '8am - 6pm Monday to Friday',
            },
            icon: '',
          },
        ],
      },
    },
  },
};
