import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BroadBandPromoIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 64 64" {...props}>
    <g
      id="Ellipse_106"
      data-name="Ellipse 106"
      fill="none"
      stroke="#e62a32"
      strokeWidth="1.506"
    >
      <circle cx="32" cy="32" r="32" stroke="none" />
      <circle cx="32" cy="32" r="31.247" fill="none" />
    </g>
    <g
      id="Group_3917"
      data-name="Group 3917"
      transform="translate(20.238 23.775)"
    >
      <g
        id="Group_4321"
        data-name="Group 4321"
        transform="translate(-8.238 -5.775)"
      >
        <path
          id="Path_2293"
          data-name="Path 2293"
          d="M479.365,333.4a10.461,10.461,0,0,0-8.216-3.711A10.24,10.24,0,0,0,463,333.4"
          transform="translate(-451.43 -314.21)"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <path
          id="Path_2294"
          data-name="Path 2294"
          d="M487.755,330.539a20.53,20.53,0,0,0-28.332.1"
          transform="translate(-453.862 -317.457)"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <path
          id="Path_2295"
          data-name="Path 2295"
          d="M495.633,327.93a29.967,29.967,0,0,0-39.52-.032"
          transform="translate(-456.113 -320.476)"
          fill="none"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
        <circle
          id="Ellipse_107"
          data-name="Ellipse 107"
          cx="2.033"
          cy="2.033"
          r="2.033"
          transform="translate(17.721 23.572)"
          fill="#e62a32"
          stroke="#e62a32"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.506"
        />
      </g>
      <path
        id="Path_2296"
        data-name="Path 2296"
        d="M467.872,335.055"
        transform="translate(-456.113 -320.476)"
        fill="none"
        stroke="#e62a32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.506"
      />
    </g>
  </Icon>
);
