import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const PromoDollarIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 63.999 63.999" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1158"
          data-name="Rectangle 1158"
          width="63.999"
          height="63.999"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Group_4307-2"
      data-name="Group 4307"
      transform="translate(0 0)"
      clip-path="url(#clip-path)"
    >
      <circle
        id="Ellipse_147"
        data-name="Ellipse 147"
        cx="31.026"
        cy="31.026"
        r="31.026"
        transform="translate(0.974 0.974)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <path
        id="Path_2515"
        data-name="Path 2515"
        d="M17.45,17.815A13.894,13.894,0,1,1,37.139,37.352"
        transform="translate(5.203 3.903)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <path
        id="Path_2516"
        data-name="Path 2516"
        d="M41.19,28.674A14.042,14.042,0,1,1,27.149,14.633,14.041,14.041,0,0,1,41.19,28.674Z"
        transform="translate(3.908 4.363)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <path
        id="Path_2517"
        data-name="Path 2517"
        d="M28.792,31.515A4.622,4.622,0,0,1,26.1,32.723v1.729H24.235V32.786a9.84,9.84,0,0,1-2.212-.4,6.3,6.3,0,0,1-1.729-.787l1.013-2.274a6.918,6.918,0,0,0,3.6,1.076q1.776,0,1.776-.889a.736.736,0,0,0-.506-.693,9.451,9.451,0,0,0-1.628-.474,15.721,15.721,0,0,1-2.056-.57,3.461,3.461,0,0,1-1.418-.974A2.627,2.627,0,0,1,20.482,25a3.121,3.121,0,0,1,.965-2.3,4.6,4.6,0,0,1,2.788-1.175V19.808H26.1V21.49a8.982,8.982,0,0,1,1.706.313,6.294,6.294,0,0,1,1.457.606l-.95,2.29a6.346,6.346,0,0,0-3.022-.84,2.534,2.534,0,0,0-1.34.273.818.818,0,0,0-.421.707.7.7,0,0,0,.5.654,8.9,8.9,0,0,0,1.6.452,15.219,15.219,0,0,1,2.064.569,3.581,3.581,0,0,1,1.418.967,2.574,2.574,0,0,1,.6,1.8,3.078,3.078,0,0,1-.927,2.235"
        transform="translate(6.051 5.906)"
        fill="#e62a32"
      />
      <line
        id="Line_250"
        data-name="Line 250"
        y1="1.946"
        x2="1.946"
        transform="translate(33.185 16.921)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <line
        id="Line_251"
        data-name="Line 251"
        y1="1.946"
        x2="1.946"
        transform="translate(37.883 18.509)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <line
        id="Line_252"
        data-name="Line 252"
        y1="1.946"
        x2="1.946"
        transform="translate(42.2 21.747)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
      <line
        id="Line_253"
        data-name="Line 253"
        y1="1.946"
        x2="1.946"
        transform="translate(44.803 26.127)"
        fill="none"
        stroke="#e62a32"
        strokeWidth="1.5"
      />
    </g>
  </Icon>
);
