import React from 'react';
import { Empty } from '../Empty';
import { useTranslation } from '../Translation';
import { useTabContext } from './Context';
import { HeaderTab } from './TabTypes';

export interface TabbedPageContentProps<T extends string> {
  tabs: Record<T, () => JSX.Element>;
}

export function TabbedPageContent<T extends string>({
  tabs,
}: TabbedPageContentProps<T>) {
  const [t] = useTranslation({
    useSuspense: true,
  });
  const tabDescriptors: HeaderTab<T>[] = t(`tabs`, {
    returnObjects: true,
  });
  const { selectedTabIndex } = useTabContext();
  const Tab: () => JSX.Element =
    tabs[tabDescriptors[selectedTabIndex]?.componentKey];
  if (!Tab) {
    return <Empty />;
  }
  return <Tab />;
}
