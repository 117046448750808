import React from 'react';

export const AppElectricity = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="40"
      viewBox="0 0 26 40"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#DD353F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.761 16.603L17.813 0 1 24.827l10.239-1.43L8.187 40 25 15.173z"
      />
    </svg>
  );
};
