import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { animated } from 'react-spring';
import { Close } from '../../Assets/Icons';
import { BurgerIcon } from '../Icons/BurgerIcon';
import './SlidingHeader.scss';

interface ItemBasic {
  label: string;
}

export interface ItemWithLink extends ItemBasic {
  link: string;
  onClick?: () => void;
}

export interface ItemWithOnclick extends ItemBasic {
  link?: string;
  onClick: () => void;
}

export type SidebarProps = {
  className?: string;
  dataTestid?: string;
  items: Array<ItemWithLink | ItemWithOnclick>;
};

const renderExternalLinkItem = ({ link, label }) => {
  return (
    <a key={label} href={link}>
      <span className="sidebar-item">{label}</span>
    </a>
  );
};

const renderButton = ({ onClick, label }) => {
  return (
    <button key={label} onClick={onClick}>
      <span className="sidebar-button">{label}</span>
    </button>
  );
};

export const Sidebar = (props: SidebarProps) => {
  const location = useLocation();
  const { className, dataTestid, items } = props;
  const [isOpen, setOpen] = useState(false);
  const isOpenClass = isOpen
    ? 'sidebar-container sidebar-open'
    : 'sidebar-container sidebar-close';
  const showOverlay = isOpen ? 'sidebar-overlay' : '';

  const renderLinkItem = useCallback(
    ({ link: routName, label, onClick }) => {
      return (
        <Link
          key={label}
          to={routName ? routName : location?.pathname}
          className={
            routName === location?.pathname ? 'sidebar-selected-item' : ''
          }
          onClick={onClick}
        >
          <span
            tabIndex={0}
            role="button"
            className={`sidebar-item ${
              routName === location?.pathname ? 'sidebar-selected-item' : ''
            }`}
          >
            {label}
          </span>
        </Link>
      );
    },
    [location?.pathname]
  );

  return (
    <div className={classNames(className, 'sidebar')} data-testid={dataTestid}>
      <div className={isOpenClass}>
        <animated.label
          onClick={() => {
            setOpen(false);
          }}
          className="sidebar-close-btn"
          tabIndex={0}
        >
          <Close className={'sidebar-icon sidebar-close-icon'} />
        </animated.label>
        {items.map((item) => {
          if (item.link?.includes('http')) {
            return renderExternalLinkItem({
              link: item.link,
              label: item.label,
            });
          } else if (item.link) {
            return renderLinkItem({
              link: item.link,
              label: item.label,
            });
          } else {
            return renderButton({
              label: item.label,
              onClick: item.onClick,
            });
          }
        })}
      </div>
      <animated.label className="sidebar-open-btn">
        <div
          onClick={() => {
            setOpen(true);
          }}
          onKeyDown={(e) => (e.key === 'Enter' ? setOpen(true) : null)}
          role="menuitem"
          tabIndex={0}
        >
          <div className="updatedIcon">
            <BurgerIcon className="sidebar-icon" />
          </div>
        </div>
      </animated.label>
      <animated.label
        className={showOverlay}
        onClick={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
