import React, { ReactElement } from 'react';
import { Icon, IconProps } from '@contact/ui-volta';

export const BurgerIcon = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      d="M26.222 29.5a1.25 1.25 0 0 1 0-2.5h19.556a1.25 1.25 0 0 1 0 2.5zm0 6.25h19.556a1.25 1.25 0 0 0 0-2.5H26.222a1.25 1.25 0 0 0 0 2.5zm0 6.25h19.556a1.25 1.25 0 0 0 0-2.5H26.222a1.25 1.25 0 0 0 0 2.5z"
      transform="translate(-20 -18)"
    />
    <path fill="none" d="M0 0H32V32H0z" />
  </Icon>
);
