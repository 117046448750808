import {
  CustomerAccount,
  useCustomerAccounts,
} from '@contact/data-access-hooks';
import { useOptionalUser } from '@contact/react';
import React from 'react';
import { SelectedTickIcon } from '../Icons';
import './PropertySwitcherList.scss';

export interface PropertySwitcherListProps {
  onPropertySelect: (
    selectedAccountId: string,
    selectedPremiseId: string
  ) => void;
  onClose: () => void;
  customItemContent?: (
    account: CustomerAccount,
    index: number,
    props: {
      onPropertySelect: (
        selectedAccountId: string,
        selectedPremiseId: string
      ) => void;
      onClose: () => void;
    }
  ) => React.ReactNode;
}

export const PropertySwitcherList = ({
  onPropertySelect,
  onClose,
  customItemContent,
}: PropertySwitcherListProps) => {
  const { token = '', ba: baId = '', premise: premiseId = '' } =
    useOptionalUser() || {};
  const { data: accounts } = useCustomerAccounts('v2', { token });

  return (
    <ul className="propertySwitcherList">
      {accounts?.map((account, index) => (
        <li className="propertySwitcherList_item" key={account.id + index}>
          <div className="propertySwitcherList_infoContainer">
            <div
              className={`propertySwitcherList_info ${
                baId === account.id ? 'propertySwitcherList_info--selected' : ''
              }`}
            >
              {account.nickname
                ? `${account.id} - ${account.nickname}`
                : `Account Number: ${account.id}`}
            </div>
            <div
              className={`propertySwitcherList_addressWrapper ${
                account.contracts && account.contracts.length > 1
                  ? 'propertySwitcherList_addressWrapper--multiple'
                  : ''
              }`}
            >
              {account.premises.map((premise) => (
                <React.Fragment key={premise.id}>
                  <div
                    className={`propertySwitcherList_address ${
                      premise.id === premiseId
                        ? 'propertySwitcherList_address--selected'
                        : ''
                    }`}
                    onClick={() => {
                      onPropertySelect(account.id, premise.id);
                      onClose();
                    }}
                    onKeyPress={(e) => {
                      onPropertySelect(account.id, premise.id);
                      onClose();
                    }}
                    tabIndex={0}
                    role="menuitem"
                  >
                    {premise.address}
                  </div>
                  <div
                    className={`propertySwitcherList_icon ${
                      premise.id == premiseId
                        ? 'propertySwitcherList_icon--selected'
                        : ''
                    }`}
                  >
                    {baId === account.id && premise.id === premiseId && (
                      <SelectedTickIcon color="#FFF" />
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            {customItemContent?.(account, index, {
              onPropertySelect,
              onClose,
            }) ?? null}
          </div>
        </li>
      ))}
    </ul>
  );
};
