import React from 'react';

export const LongArrowDown = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#6E0D83"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
      >
        <path
          d="M6.9 18.4L6.9 0M0 6.9L6.9 0 13.8 6.9"
          transform="rotate(180 8.25 10.775)"
        />
      </g>
    </svg>
  );
};
